<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" text small v-bind="attrs" v-on="on">
        <span :class="[{ 'caption mt-1': withIcon }]">{{
          $t("dialogs.support.support")
        }}</span>
        <PIcon v-if="withIcon" icon="support" :color="color" />
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-card-title class="justify-center primary--text overline">
        <span>{{ $t("dialogs.support.title") }}</span>
      </v-card-title>

      <v-card-text v-if="support_sent">
        <span v-html="$t('dialogs.support.support_success')" />
      </v-card-text>

      <v-card-text v-if="!support_sent">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.support.props.name") }}*
              </h5>
              <v-text-field
                v-model="name"
                :disabled="isLoggedIn"
                required
                dense
                :rules="[rules.notEmpty]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.support.props.email") }}*
              </h5>
              <v-text-field
                v-model="email"
                dense
                :disabled="isLoggedIn"
                required
                :rules="[rules.emailInput]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.support.props.tel") }}
              </h5>
              <v-text-field v-model="phone" dense />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.support.props.image_upload") }}
              </h5>
              <FileUpload
                v-if="dialog"
                @pendingUpload="setUploadPending"
                @uploaded="setImages"
                @clear="setImages('')"
              />
              <div v-if="images && images.length > 0">
                <span
                  class="text--caption"
                  v-html="
                    $t('dialogs.support.upload_info', { count: images.length })
                  "
                />
                <v-btn x-small text color="error" @click="images = []">
                  {{ $t("views.questions.delete") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12">
              <h5 class="text--caption text--text font-weight-bold mb-2">
                {{ $t("dialogs.support.props.message") }}*
              </h5>
              <v-textarea
                v-model="message"
                no-resize
                outlined
                required
                :rules="[rules.notEmpty, rules.maxLength]"
              />
            </v-col>
          </v-row>
          <small>{{ $t("dialogs.support.required") }}</small>
        </v-container>
        <v-expand-transition>
          <v-alert
            v-model="showMaxRequestWarning"
            v-if="showMaxRequestWarning"
            dismissible
            dense
            type="error"
            ><span class="caption">{{
              $t("ng.show_max_request_warning", {
                time: requestTimer,
              })
            }}</span></v-alert
          >
        </v-expand-transition>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!support_sent" color="" text small @click="closeDialog()">
          {{ $t("views.questions.cancel") }}
        </v-btn>
        <v-btn
          v-if="!support_sent"
          color="success"
          depressed
          small
          :disabled="!formValid || showMaxRequestWarning"
          @click="submit()"
        >
          {{ $t("views.questions.create") }}
        </v-btn>
        <v-btn
          v-if="support_sent"
          color="primary"
          text
          small
          @click="closeDialog()"
        >
          {{ $t("dialogs.actions.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileUpload from "@/components/global/FileUpload";

export default {
  name: "SupportDialog",
  components: {
    FileUpload,
  },
  props: {
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: "check",
    },
    smallIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      name: "",
      email: "",
      phone: "",
      message: "",
      images: [],
      support_sent: false,
      upload_pending: false,
      rules: {
        emailInput: (value) =>
          (!!value && value.match(/\S+@\S+\.\S+/) != null) ||
          this.$t("views.login.invalidEmail", this.$i18n.locale),
        notEmpty: (value) =>
          !!value || this.$t("views.login.required", this.$i18n.locale),
        maxLength: (v) =>
          (!!v && v.length <= 255) ||
          this.$t("ng.too_many_chars_hint", {
            count: v.length,
            max: 255,
          }),
      },
      showMaxRequestWarning: false,
      requestTimer: 60,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    isLoggedIn() {
      return this.currentUser && Object.keys(this.currentUser).length !== 0;
    },
    formValid() {
      return (
        this.name.length > 0 &&
        this.email.length > 0 &&
        this.message.length > 0 &&
        this.message.length <= 255 &&
        !this.support_sent &&
        !this.upload_pending
      );
    },
  },
  watch: {
    dialog(val) {
      if (val) this.initDialog();
    },
  },
  methods: {
    ...mapActions("app", ["add_support"]),
    initDialog() {
      this.name = this.isLoggedIn
        ? `${this.currentUser.firstName} ${this.currentUser.lastName}`
        : "";
      this.email = this.isLoggedIn ? this.currentUser.userEmail : "";
      this.phone = "";
      this.message = "";
      this.images = [];
    },
    closeDialog() {
      this.dialog = false;
      this.support_sent = false;
    },
    async submit() {
      let payload = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        user_agent: navigator.userAgent,
        message: this.message,
        images: this.images ? this.images.join() : "",
      };

      let res = await this.add_support(payload);

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("views.subscriptions.success"),
          text: this.$t("dialogs.support.notification_success"),
        });
        this.support_sent = true;
      } else {
        if (res._status === 429) {
          this.showMaxRequestWarning = true;
          this.initTimer();
        }
        this.$notify({
          type: "error",
          title: this.$t("views.subscriptions.error"),
          text: this.$t("dialogs.support.notification_error"),
        });
      }
    },
    setImages(images) {
      this.images = images;
    },
    setUploadPending(status) {
      this.upload_pending = status;
    },
    initTimer() {
      if (this.requestTimer > 0) {
        setTimeout(() => {
          this.requestTimer -= 1;
          this.initTimer();
        }, 1000);
      } else {
        this.showMaxRequestWarning = false;
        this.requestTimer = 60;
      }
    },
  },
};
</script>

<style></style>
