<template>
  <div>
    <v-avatar
      :size="size"
      :class="{
        'elevation-2': elevated && !highlighted && !disableLink,
        clickable: !disableLink,
        highlighted: highlighted,
      }"
      :tile="tile"
      @click.stop="showProfile()"
    >
      <v-img
        :src="image"
        :class="{ transparent: transparent }"
        :alt="
          disableLink
            ? $t('images.user.avatar_inactive')
            : $t('images.user.avatar_active')
        "
      />
    </v-avatar>

    <ProfileDialog
      :visible="showProfileDialog"
      :user-id="userId"
      @close="closeProfile()"
    />
  </div>
</template>

<script>
import ProfileDialog from "@/components/user/dialogs/ProfileDialog";

export default {
  name: "UserAvatar",
  components: {
    ProfileDialog,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      required: false,
      default: 32,
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    elevated: {
      type: Boolean,
      required: false,
      default: false,
    },
    tile: {
      type: Boolean,
      required: false,
      default: false,
    },
    transparent: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showProfileDialog: false,
    };
  },
  watch: {
    showDialog(v) {
      this.showProfileDialog = v;
    },
  },
  methods: {
    showProfile() {
      if (this.disableLink) return false;
      this.showProfileDialog = true;
    },
    closeProfile() {
      this.showProfileDialog = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.transparent {
  opacity: 0.5 !important;
}
.highlighted {
  box-shadow: 0 0 8px var(--v-primary-darken3) !important;
  border: 3px solid var(--v-primary-base) !important;
}
</style>
