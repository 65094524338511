//
// CUSTOM STRUCTURE THEMES
//
export const THEMES = {
  lebezeit: {
    primary: "#eb640a",
    secondary: "#4b4b4b",
    accent: "#a0a0a0",
    success: "#97c15c",
    error: "#e25241",
    info: "#28190a",
    warning: "#ffcc00",
    background: "#ffffff",
    text: "#4D4D4D",
  },
  myschoolcare: {
    primary: "#6f6f6f",
    secondary: "#c8d732",
    accent: "#a0a0a0",
    success: "#97c15c",
    error: "#e25241",
    info: "#28190a",
    warning: "#ffcc00",
    background: "#ffffff",
    text: "#4D4D4D",
  },
};
