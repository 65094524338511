import Vue from "vue";
import Vuetify from "vuetify";
import CountryFlag from "vue-country-flag";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);
Vue.component("country-flag", CountryFlag);

//
// COLOR DEFINITIONS: GENERAL
//
const success = Object.freeze({
  base: "#97c15c",
  lighten1: "#b3dd76",
  lighten2: "#cffa91",
  lighten3: "#ecffac",
  darken1: "#7ca642",
  darken2: "#618b29",
  darken3: "#47720b",
});
const error = Object.freeze({
  base: "#e25241",
  lighten1: "#ff6e59",
  lighten2: "#ff8972",
  lighten3: "#ffa58b",
  darken1: "#c2352a",
  darken2: "#a31214",
  darken3: "#850000",
});
const warning = Object.freeze({
  base: "#ffcc00",
  lighten1: "#ffe836",
  lighten2: "#ffff57",
  lighten3: "#ffff75",
  darken1: "#e0b100",
  darken2: "#c19600",
  darken3: "#a37d00",
});
const info = Object.freeze({
  base: "#28190a",
  lighten1: "#3e2e21",
  lighten2: "#564436",
  lighten3: "#6f5b4d",
  darken1: "#170000",
  darken2: "#000000",
  darken3: "#000000",
});

//
// COLOR DEFINITIONS: DEFAULT
//
const primary = Object.freeze({
  base: "#00afd7",
  lighten1: "#43cbf4",
  lighten2: "#68e7ff",
  lighten3: "#89ffff",
  darken1: "#0094bb",
  darken2: "#007aa0",
  darken3: "#006186",
});

const secondary = Object.freeze({
  base: "#617d8b",
  lighten1: "#7a97a5",
  lighten2: "#95b2c0",
  lighten3: "#b0cddc",
  darken1: "#486472",
  darken2: "#314c59",
  darken3: "#193542",
});
const accent = Object.freeze({
  base: "#a0a0a0",
  lighten1: "#bbbbbb",
  lighten2: "#d7d7d7",
  lighten3: "#f3f3f3",
  darken1: "#868686",
  darken2: "#6d6d6d",
  darken3: "#545454",
});

//
// DEFAULT THEME
//
const schemes = {
  default: {
    primary: primary,
    secondary: secondary,
    accent: accent,
    success: success,
    error: error,
    info: info,
    warning: warning,
    background: "#ffffff",
    text: "#4D4D4D", //'#617D8B'
  },
};

const scheme = schemes["default"];

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: scheme,
    },
  },
});
