<template>
  <v-app
    :class="[
      `p3l-app ${getFont}`,
      $vuetify.breakpoint.name,
      bottomNavigation.visible ? 'navbar-bottom' : '',
    ]"
  >
    <TopNavigation v-if="currentUser && topNavigation.visible" />
    <v-main ref="main" class="background">
      <AppLoading2 />
      <router-view
        v-if="!loading"
        id="router-main"
        :key="$route.fullPath"
        :class="{ 'navbar-top': topNavigation.visible }"
        class="page-content"
      />
      <AppLoading v-if="loading" />
    </v-main>
    <CookiePolicy />
    <BottomNavigation v-if="bottomNavigation.visible" />
    <AppMenu />
    <notifications position="bottom left" class="alerts" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TopNavigation from "@/components/_app/TopNavigation";
import BottomNavigation from "@/components/_app/BottomNavigation.vue";
import CookiePolicy from "@/components/layout/CookiePolicy.vue";
import AppMenu from "@/components/_app/AppMenu";
import AppLoading from "@/components/_layout/AppLoading";
import AppLoading2 from "@/components/_layout/AppLoading2";
import "@fontsource/roboto/";
import "@fontsource/montserrat";
import "@fontsource/open-sans";
import { THEMES } from "@/plugins/customThemes.js";

export default {
  name: "App",
  components: {
    BottomNavigation,
    CookiePolicy,
    TopNavigation,
    AppMenu,
    AppLoading,
    AppLoading2,
  },
  data() {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp,
      loading: true,
    };
  },
  computed: {
    ...mapGetters("auth", { currentUser: "get_current_user" }),
    ...mapGetters("app", {
      bottomNavigation: "get_bottomnavigation_settings",
      topNavigation: "get_topnavigation_settings",
      alert: "get_alert",
      menu: "get_menu",
      language: "get_language",
    }),
    ...mapGetters("community", { settings: "get_settings" }),
    getFont() {
      if (!process.env.VUE_APP_FONT) return "font-roboto";
      return `font-${process.env.VUE_APP_FONT.toLowerCase()}`;
    },
  },
  watch: {
    $route() {
      if (
        this.currentUser &&
        this.currentUser.id &&
        this.currentUser.is_deleted == 0
      ) {
        this.fetch_notification_count();
      }
    },
    alert() {
      this.showAlert();
    },
    language(v) {
      if (v !== this.language) this.checkAppSettings();
    },
    currentUser(cur, old) {
      if (cur && !old) {
        this.fetchUser();
        this.setTheme();
      }
      if (!cur) {
        this.setTheme();
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem("locale")) {
      this.$i18n.locale = localStorage.getItem("locale");
      this.loading = false;
    }
    localStorage.setItem("locale", this.$i18n.locale);
    this.checkAppSettings();
  },
  created() {
    const html = document.documentElement;
    html.setAttribute("lang", this.$i18n.locale);
    this.set_language(this.$i18n.locale);
  },
  methods: {
    ...mapActions("auth", ["get_user"]),
    ...mapActions("notifications", ["fetch_notification_count"]),
    ...mapActions("community", ["fetch_settings"]),
    ...mapMutations("app", ["set_language"]),
    showAlert() {
      if (!this.alert) return false;
      this.$notify({
        type: this.alert.type,
        title: this.$t(this.alert.title),
        text: this.$t(this.alert.text),
      });
    },
    async fetchUser() {
      await this.get_user(this.currentUser.id);
    },
    async checkAppSettings() {
      this.loading = true;
      var res = await this.fetch_settings({ language: this.$i18n.locale });
      if (res && res._status === 200) {
        localStorage.setItem("appsettings", JSON.stringify(res));
        this.setTheme();
      } else {
        console.error("saved language not found. will use default");
        var fallback_locale = this.$i18n.availableLocales[0];
        var res_check = await this.fetch_settings({
          language: fallback_locale,
        });
        if (res_check && res_check._status === 200) {
          this.$i18n.locale = fallback_locale;
          localStorage.setItem("locale", fallback_locale);
          localStorage.setItem("appsettings", JSON.stringify(res));
          this.setTheme();
        }
      }
      this.loading = false;
    },
    setTheme() {
      // CUSTOM STRUCTURE THEME START
      if (process.env.VUE_APP_CUSTOM_STRUCTURE_THEME) {
        var customStructureThemes = JSON.parse(
          process.env.VUE_APP_CUSTOM_STRUCTURE_THEME
        );
      }
      if (customStructureThemes && this.currentUser) {
        var customStructureThemesIds = customStructureThemes.map((t) => t[0]);
        var userStructureIds = [];
        this.currentUser.organisationStructures.forEach((s) =>
          userStructureIds.push(s.id)
        );
        userStructureIds.forEach((s) => {
          if (customStructureThemesIds.includes(s)) {
            var themeName = customStructureThemes.filter(
              (t) => t[0] === s
            )[0][1];
            var theme = THEMES[themeName];
            this.$vuetify.theme.themes.light = theme;
            return false;
          }
        });
        return false;
      }
      // CUSTOM STRUCTURE THEME END

      var currentTheme = this.$vuetify.theme.themes.light;
      var props = [
        "primary",
        "secondary",
        "accent",
        "error",
        "warning",
        "info",
        "background",
        "text",
      ];
      var color_settings = { ...this.settings.theme };
      var custom_theme = { ...currentTheme };
      if (!color_settings || Object.keys(color_settings).length === 0)
        return false;

      props.forEach((prop) => {
        var custom_color = color_settings[prop];
        if (!custom_color) return false;
        let prop_type = custom_color.constructor.name;
        if (prop_type === "String") custom_theme[prop] = custom_color;
        if (prop_type === "Array") {
          if (custom_color.length !== 7) {
            console.warn("not enough color definitions (1 STR || Array[7])");
            return false;
          }
          let color = {
            base: custom_color[0],
            lighten1: custom_color[1],
            lighten2: custom_color[2],
            lighten3: custom_color[3],
            darken1: custom_color[4],
            darken2: custom_color[5],
            darken3: custom_color[6],
          };
          custom_theme[prop] = color;
        }
      });
      this.$vuetify.theme.themes.light = custom_theme;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/typography.scss";

body {
  overflow: hidden;
}

.p3l-app {
  overflow: hidden;
}
.p3l-app .page-content {
  min-height: calc(100% - 80px);
}

// HELPERS TODO: Still needed?

.v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(
    .v-tabs-bar--show-arrows
  ):not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}
.v-slide-group__prev .v-slide-group__prev--disabled {
  display: none !important;
}

.v-slider__ticks-container {
  .v-slider__tick:first-child {
    .v-slider__tick-label {
      color: #00afd7 !important;
      font-weight: bold;
    }
  }
}
.v-input--slider .v-slider__ticks > span {
  color: #00ff !important;
}

// other
.alerts {
  z-index: 99999 !important;
}

// app max-width
@import "~vuetify/src/styles/settings/variables";
@media #{map-get($display-breakpoints, 'xs-only')} {
  .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')} {
  .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
@media #{map-get($display-breakpoints, 'xl-only')} {
  .container {
    max-width: 1280px !important;
  }
}
</style>
